import { v4 as uuid } from "uuid";
import { request } from "../helpers";
import { dispatchEvent, getMapEditorConfig } from "../lib/utils";

require("../lib/polygon-map-editor");
export default class extends ApplicationController {
  static currentlyOpenedId;
  static editor;
  static values = {
    auxFetchUrl: String,
    auxStoreUrl: String,
    defaultPolygonStyle: String,
    fetchUrl: String,
    focusOn: String,
    geometryUpdateDate: String,
    geometryUrl: String,
    infoUrl: String,
    initialLayer: String,
    initialTool: String,
    mapApiKey: String,
    nonEditableIds: String,
    paramName: String,
    replaceTiles: String,
    showLabels: String,
    tilesUrl: String,
    usageType: String,
  };

  connect() {
    const editorConfig = getMapEditorConfig(this.geometryUpdateDateValue, this.tilesUrlValue, this.mapApiKeyValue);
    if (this.defaultPolygonStyleValue) {
      editorConfig.polygonStyle = JSON.parse(this.defaultPolygonStyleValue);
    }
    this.editor = new window.PolygonMapEditor(this.element, editorConfig);

    document.addEventListener("map:ready", this.initializeMap.bind(this));

    document.addEventListener(
      "map:polygon-created",
      this.performCreate.bind(this)
    );

    document.addEventListener(
      "map:polygon-modified",
      this.performUpdate.bind(this)
    );

    document.addEventListener(
      "map:polygon-deleted",
      this.performDestroy.bind(this)
    );

    document.addEventListener(
      "map:polygon-info-form-requested",
      this.requestPopup.bind(this)
    );

    document.addEventListener(
      "map:maybe-update-polygon-title",
      this.maybeUpdatePolygonTitle.bind(this)
    );

    document.addEventListener(
      "map:default-mode",
      this.setInitialTool.bind(this)
    );
  }

  disconnect() {
    if (this.editor) {
      this.editor.disconnect();
      this.editor = null;
    }
  }

  initializeMap({ detail: { map }}) {
    if (map) {
      if (this.replaceTilesValue) {
        map.eachLayer(layer => {
          if (layer._url?.includes('uhul.cz')) {
            layer.setUrl(this.replaceTilesValue)
          }
        });
      }

      if (this.initialLayerValue) {
        setTimeout(() => {
          const i = Number(this.initialLayerValue);
          const layerSwitches = [...document.querySelectorAll('.leaflet-control-layers-base input[type="radio"]')];
          if (i >=0 && layerSwitches.length > i) {
            layerSwitches[i].click();
          }
        }, 100);
      }
    }
    this.fetchGeometries();
  }

  async fetchGeometries() {
    await this.fetchData(this.initializeGeometries.bind(this));
    if (this.auxFetchUrlValue) {
      await this.fetchAuxData(this.initializeAuxGeometries.bind(this));
    }
  }

  async performCreateJointHunting(response) {
    const formData = new FormData();
    formData.append(`joint_hunting[geometry_id]`, response.id);

    this.setInitialTool();

    try {
      const response = await request.post(this.auxStoreUrlValue, {
        body: formData,
        responseKind: "json",
      });
    } catch (err) {
      errorToConsole(err.message);
    }
  }

  async performCreateHuntingSection(response) {
    const formData = new FormData();
    formData.append(`hunting_section[geometry_id]`, response.id);

    this.setInitialTool();

    try {
      const response = await request.post(this.auxStoreUrlValue, {
        body: formData,
        responseKind: "json",
      });
    } catch (err) {
      errorToConsole(err.message);
    }
  }

  async performCreateDangerSection(response) {
    const formData = new FormData();
    formData.append(`danger_section[geometry_id]`, response.id);

    this.setInitialTool();

    try {
      const response = await request.post(this.auxStoreUrlValue, {
        body: formData,
        responseKind: "json",
      });
    } catch (err) {
      errorToConsole(err.message);
    }
  }

  async performCreate(event) {
    const { detail } = event;
    const identifier = uuid();
    const coordinates = detail.points.map((x) => [x.lat, x.lng]);

    console.info(`🚀 Creating polygon w/ ID ${identifier}`);

    this.setInitialTool();

    let formData = new FormData();
    formData.append(`${this.paramNameValue}[uuid]`, identifier);
    formData.append(`${this.paramNameValue}[geometry_type]`, detail.type);
    formData.append(`${this.paramNameValue}[points]`, coordinates);
    if (this.usageTypeValue) {
      formData.append(`${this.paramNameValue}[usage_type]`, this.usageTypeValue);
    }

    try {
      const response = await request.post(this.geometryUrlValue, {
        body: formData,
        responseKind: "json",
      });

      this.editor.setId(detail.ref, response.id);

      if (response && response?.geometry_type === "polygon") {
        if (this.usageTypeValue === 'joint_hunting') {
          await this.performCreateJointHunting(response);
        } else if (this.usageTypeValue === 'hunting_section') {
          await this.performCreateHuntingSection(response);
        } else if (this.usageTypeValue === 'danger_section') {
          await this.performCreateDangerSection(response);
        }
        dispatchEvent(document, "map:polygon-info-form-requested", {
          detail: {
            id: response.id,
            points: detail.points,
            type: response.geometry_type,
          },
        });
      }
    } catch (err) {
      errorToConsole(err.message);
    }
  }

  async performUpdate(event) {
    const { detail } = event;
    const identifier = detail.id;
    if (!identifier) {
      return
    }
    const coordinates = detail.points.map((x) => [x.lat, x.lng]);

    console.info(`🚀 Edititng polygon w/ ID ${identifier}`);

    let formData = new FormData();
    formData.append(`${this.paramNameValue}[points]`, coordinates);

    try {
      const response = await request.put(
        `${this.geometryUrlValue}/${identifier}`,
        {
          body: formData,
          responseKind: "json",
        }
      );
    } catch (err) {
      errorToConsole(err.message);
    }
  }

  async performDestroy(event) {
    const { detail } = event;

    try {
      await request.delete(`${this.geometryUrlValue}/${detail.id}`, {
        responseKind: "json",
      });
    } catch (err) {
      errorToConsole(err.message);
    }
  }

  async requestPopup(event) {
    const { detail } = event;

    const nonEditableIds = this.getNonEditableIds();
    if (nonEditableIds?.includes?.(detail.id)) {
      return;
    }

    let method;
    if (this.usageTypeValue === 'joint_hunting') {
      method = 'assign_joint_hunting';
    } else if (this.usageTypeValue === 'hunting_section') {
      method = 'assign_hunting_section';
    } else if (this.usageTypeValue === 'danger_section') {
      method = 'assign_danger_section';
    } else {
      method = 'assign';
    }

    try {
      const response = await request.get(
        `${this.geometryUrlValue}/${detail.id}/${method}`,
        {
          responseKind: "xhr",
        }
      );

      openModal(response);
      this.handleModalOpen(detail.id);
    } catch (err) {
      errorToConsole(err.message);
    }
  }

  maybeUpdatePolygonTitle(event) {
    const { detail } = event;
    if ((this.showLabelsValue || '').split(',').includes(detail?.usage_type)) {
      const ref = this.editor.getPolygonReferenceById(detail?.id);
      if (ref) {
        if (ref?.getTooltip?.()) {
          ref?.setTooltipContent?.(detail?.label || ' ');
        } else {
          ref?.bindTooltip?.(detail?.label || ' ', {
            className: `map-tooltip-${detail?.usage_type}`,
            direction: 'center',
            permanent: true
          });
        }
      }
    }
  }

  initializeGeometries(geometries) {
    let coords = [];

    let totalBounds = null;
    let focusGeometryBounds = null;

    geometries.forEach((geometry) => {
      const {
        id,
        type,
        coordinates,
        options: { label, outline, style, verified, usage_type },
      } = geometry;

      if (this.editor) {
        const polygon = this.editor.addPolygon(id, type, coordinates, style);

        const bounds = polygon.getBounds();
        if (!totalBounds) {
          totalBounds = bounds;
        } else {
          totalBounds.extend(bounds);
        }

        if (this.focusOnValue && Number(this.focusOnValue) === Number(id)) {
          focusGeometryBounds = bounds;
        }

        if ((this.showLabelsValue || '').split(',').includes(geometry?.options?.usage_type)) {
          polygon.bindTooltip(label || ' ', {
            className: `map-tooltip-${geometry?.options?.usage_type}`,
            direction: 'center',
            permanent: true
          });
        }

        // if hunting trail map is shown, zoom on hunting_trail only (#29420)
        if (this.usageTypeValue === "hunting_trail" && usage_type === "hunting_trail") {
          coords.push(coordinates);
        }

        this.editor.setPolygonVerified(id, verified)

        // if (type === "polygon") this.editor.setPolygonText(id, label);
      }
    });

    if (this.editor) {
      this.editor.setFixedPolygons(this.getNonEditableIds());
    }

    this.setInitialTool();
    if (focusGeometryBounds) {
      this.map.fitBounds(focusGeometryBounds);
    } else if (totalBounds) {
      this.map.fitBounds(totalBounds);
    } else if (coords && coords.length) {
      this.setCenter(coords);
    }
  }

  setInitialTool() {
    if (this.initialToolValue) {
      setTimeout(() => {
        if (this.map) {
          const i = Number(this.initialToolValue);
          const links = [...this.map._container.querySelectorAll('.leaflet-bar.leaflet-control')].flatMap(x => {
            if (x.classList.contains('leaflet-control-zoom')) {
              return [];
            }
            return [...x.querySelectorAll('a')];
          });
          links?.[i - 1]?.click?.();
        }
      }, 10);
    }
  }

  getNonEditableIds() {
    return (this.nonEditableIdsValue || '').split(',').filter(str => str !== '').map(Number);
  }

  initializeAuxGeometries(geometries) {
    let coords = [];

    geometries.forEach((geometry) => {
      const {
        type,
        coordinates,
        options: { style },
      } = geometry;

      if (this.editor) {
        const polygon = this.editor.addPolygon(null, type, coordinates, style);
      }
    });
  }

  async fetchData(callback) {
    try {
      const response = await request.get(this.fetchUrlValue, {
        contentType: "application/json",
        responseKind: "json",
      });

      callback(response);
    } catch (err) {
      errorToConsole(err.message);
      throw err;
    }
  }

  async fetchAuxData(callback) {
    try {
      const response = await request.get(this.auxFetchUrlValue, {
        contentType: "application/json",
        responseKind: "json",
      });

      callback(response);
    } catch (err) {
      throw err;
      errorToConsole(err.message);
    }
  }

  setCenter(coords) {
    if (coords.length === 0) return;
    this.map.fitBounds(coords);
  }

  handleModalOpen(identifier) {
    if (identifier == null) return;

    this.currentlyOpenedId = identifier;
    const ref = this.editor.getPolygonReferenceById(identifier);

    if (ref) {
      ref._path?.classList?.add?.('path-highlighted');
      this.scheduleModalClose();
    }
  }

  scheduleModalClose(polygon, color, fillOpacity) {
    $("#map-editor-modal").on("hidden.bs.modal", () => {
      document.querySelectorAll('.path-highlighted').forEach(element => {
        element?.classList?.remove?.('path-highlighted');
      });
    });
  }

  get map() {
    if (this.editor == null) return;
    return this.editor.map;
  }
}

const openModal = (content) => {
  const portal = document.getElementById("root");
  portal.innerHTML = content;

  $(".modal-backdrop").remove();
  $("#map-editor-modal").modal();
  $("#map-editor-modal").on("shown.bs.modal", () => initApplicationPlugins());
};

const errorToConsole = (value) => {
  const message = `🚨 %c[MapEditor Error]: ${value}`;
};
